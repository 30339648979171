import { request } from "../utils/axios";
import { stringify } from "qs";

// 入口列表
export function list(params) {
  return request({
    url: `/wuan/admin/index/tag/list?${stringify(params)}`,
    method: "get"
  });
}

// 修改tag
export function edit(params) {
  return request({
    url: `/wuan/admin/index/tag/saveOrUpdate?${stringify(params)}`,
    method: "post"
  });
}
