<template>
  <a-table
    bordered
    rowKey="id"
    size="middle"
    :columns="columns"
    :data-source="source"
  >
    <template #operation="{ record }">
      <a-button type="primary" size="small" @click="editTag(record)"
        >修改</a-button
      >
    </template>
  </a-table>
  <a-modal
    title="修改"
    v-model:visible="tagModal.visible"
    :confirm-loading="tagModal.loading"
    @ok="handleOk"
    @cancel="handlerCancel"
  >
    <a-form
      ref="formRef"
      :model="tagModal.data"
      :rules="tagModal.rules"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 19 }"
    >
      <a-form-item label="入口id" name="id">{{ tagModal.data.id }}</a-form-item>
      <a-form-item label="入口名称" name="tagName">
        <a-input v-model:value="tagModal.data.tagName" />
      </a-form-item>
      <a-form-item label="入口类型" name="type">
        <a-select
          v-model:value="tagModal.data.type"
          placeholder="请选择"
          style="width: 50%"
        >
          <a-select-option
            v-for="item in typeArr"
            :key="item.value"
            :value="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="跳转地址" name="link">
        <a-input v-model:value="tagModal.data.link" />
      </a-form-item>
      <a-form-item label="入口图标" name="icon">
        <a-upload
          v-model:fileList="fileList"
          name="file"
          accept="image/*"
          list-type="picture"
          :action="qiniuUrl"
          :data="fileData"
          :beforeUpload="handlerBeforeUpload"
          @change="handlerFileChange"
        >
          <a-button> 上 传 </a-button>
        </a-upload>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { list, edit } from "../api/tag";
import { uploadFile } from "../api/planet";
import { message } from "ant-design-vue";
import urls from "../common/urls";

const columns = ref([
  { title: "ID", dataIndex: "id", width: "15%" },
  { title: "标题", dataIndex: "tagName", width: "65%" },
  {
    title: "操作",
    dataIndex: "operation",
    width: "20%",
    slots: { customRender: "operation" },
  },
]);
const source = ref([]);

const formRef = ref();
const typeArr = ref([
  {
    value: 0,
    label: "内部链接",
  },
  {
    value: 1,
    label: "外部链接",
  },
  {
    value: 2,
    label: "淘宝链接",
  },
  {
    value: 3,
    label: "饿了么链接",
  },
]);
const tagModal = ref({
  visible: false,
  loading: false,
  data: {
    id: "",
    type: "",
    tagName: "",
    link: "",
    icon: "",
  },
  rules: {},
});
const editTag = (record) => {
  tagModal.value.data = { ...record };
  if (record.icon) {
    const key = record.icon.split("/").pop();
    fileList.value = [
      {
        name: key || "",
        response: { key: key || "" },
        status: "done",
        url: record.icon,
        uid: "-1",
      },
    ];
  }
  tagModal.value.visible = true;
};
const handleOk = async () => {
  const { data, msg, status } = await edit(tagModal.value.data);
  if (status === "200") {
    message.success(msg);
    handlerCancel();
    query();
  } else {
    message.error(msg);
  }
};
const handlerCancel = () => {
  tagModal.value.visible = false;
  tagModal.value.data = {
    id: "",
    type: "",
    tagName: "",
    link: "",
    icon: "",
  };
};

const qiniuUrl = urls.qiniuBase;
const fileList = ref([]);
const fileData = ref({});
const handlerBeforeUpload = async (file) => {
  const { data } = await uploadFile(file.name);
  fileData.value = data;
};
const handlerFileChange = ({ file }) => {
  const { status, response } = file;
  if (status === "done") {
    tagModal.value.data.icon = `${urls.qiniu}${response.key}`;
    if (response.key) {
      fileList.value = [
        {
          name: response.key,
          response: { key: response.key },
          status: "done",
          url: `${urls.qiniu}${response.key}`,
          uid: "-1",
        },
      ];
    }
  }
};

const query = async () => {
  const { data, msg, status } = await list();
  if (status === "200") {
    source.value = data;
  } else {
    message.error(msg);
  }
};
onMounted(query);
</script>